import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { Capacitor } from '@capacitor/core'
import { Trans, t } from '@lingui/macro'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { FileOpener } from '@capacitor-community/file-opener'

const ContractDownloadLink = ({ contractId, institutionId }: { contractId: string; institutionId: string }) => {
  const [contractPdfUrl, setContractPdfUrl] = useState<string | undefined>(undefined)
  const [contractBlob, setContractBlob] = useState<Blob | undefined>(undefined)

  useEffect(() => {
    const getContract = async () => {
      if (institutionId) {
        const response = await fetch(
          `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institutionId}/guardian/contract-pdf?contractId=${contractId}&institutionId=${institutionId}`,
          {
            headers: { 'Content-Type': 'application/pdf' },
          }
        )
        const contract = await response.blob()

        if (Capacitor.isNativePlatform()) {
          setContractBlob(contract)
        } else {
          setContractPdfUrl(URL.createObjectURL(contract))
        }
      } else {
        throw Error('Need institutionId as param in order to load leaflet.')
      }
    }
    if (contractPdfUrl === undefined) {
      getContract()
    }
  })

  if (!contractPdfUrl && !contractBlob) {
    return <LoadingOutlined />
  } else {
    return (
      <React.Fragment>
        {Capacitor.isNativePlatform() ? (
          <>
            {contractBlob !== undefined && (
              <Button type="link" onClick={() => openPdfOnNativePlatform(contractBlob)}>
                <FilePdfFilled />{' '}
                <u>
                  <Trans>Betreuungsvertrag</Trans>.pdf
                </u>
              </Button>
            )}
          </>
        ) : (
          <Button type="link" href={contractPdfUrl} target="_blank" rel="noopener noreferrer">
            <FilePdfFilled />{' '}
            <u>
              <Trans>Betreuungsvertrag</Trans>.pdf
            </u>
          </Button>
        )}
      </React.Fragment>
    )
  }
}

export default ContractDownloadLink

const openPdfOnNativePlatform = (contractBlob: Blob) => {
  const filename = t({ message: 'Betreuungsvertrag' }) + '.pdf'
  const reader = new FileReader()
  reader.onloadend = async function () {
    const base64data = reader.result as string
    await Filesystem.writeFile({
      path: filename,
      data: base64data,
      directory: Directory.Cache,
    })
    const getUriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: filename,
    })
    const path = getUriResult.uri
    FileOpener.open({ filePath: path, contentType: 'application/pdf' })
  }
  reader.readAsDataURL(contractBlob)
}
