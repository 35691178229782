import { t } from '@lingui/macro'
import { LogbookType } from '../types'

export const translateLogbookType = (logbookType: LogbookType) => {
  switch (logbookType) {
    case 'checkIn':
      return t({ message: 'Check\u2011In' })
    case 'checkOut':
      return t({ message: 'Check\u2011Out' })
    case 'fallAsleep':
      return t({ message: 'Eingeschlafen' })
    case 'wakeup':
      return t({ message: 'Aufgewacht' })
    case 'meal':
      return t({ message: 'Mahlzeit' })
    case 'activity':
      return t({ message: 'Aktivität' })
  }
}
