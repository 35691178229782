import { Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { CheckGuardianDataRouter } from './check-guardian-data-router'
import { OverviewPage } from './overview-page'
import { AddChildContractRouter } from './add-child-contract-router'
import { useEffect } from 'react'
import { useGuardianContext } from '../guardian-context'

const NextSchoolYearRouter = () => {
  const fetchTaxInformation = useGuardianContext((ctx) => ctx.fetchTaxInformation)
  const { pathname } = useLocation()
  useEffect(() => {
    fetchTaxInformation()
  }, [fetchTaxInformation])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Outlet />
      <Routes>
        <Route path="*" element={<OverviewPage />} />
        <Route path="check-guardian-data/*" element={<CheckGuardianDataRouter />} />
        <Route path="add-child-contract/:childId/*" element={<AddChildContractRouter />} />
      </Routes>
    </>
  )
}

export { NextSchoolYearRouter }
