import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications'
import { t } from '@lingui/macro'
import { Guardian } from '../../../types'
import type { GuardianContext } from './guardian-context'
import { Capacitor } from '@capacitor/core'

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

type Ensure<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>

export const registerForPushNotifications = (state: Ensure<GuardianContext, 'guardian' | 'family'>) => {
  if (Capacitor.getPlatform() === 'web') return

  console.log('Initializing push notification')
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register()
    } else {
      alert(
        t({
          id: 'notification.didntGrantPermission',
          message: `Bitte erlauben Sie das senden von Notifikationen, damit sie über neue Nachrichten von der Institution informiert werden.`,
        })
      )
      console.error('Error on requestPermissions: ' + JSON.stringify(result.receive))
    }
  })

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', (token: Token) => {
    console.log('Push registration success, token:')
    console.log(token.value)
    registerDeviceOnBackend({ guardian: state.guardian, token: token.value })
  })

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error: any) => {
    console.error('Error on registration: ' + JSON.stringify(error))
    alert(
      t({
        id: 'notification.registrationError',
        message: `Leider war es nicht möglich das Geräte für Notifikationen zu registrieren. Sie werden keine Notifikationen erhalten. Bitte wenden Sie sich an Ihre Institution.`,
      })
    )
  })

  PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
    const child = state.family.children.find((child) => child.id === notification.data.childId)
    state.setNewMessageId(notification.data.messageId)
    if (child) {
      state.setCurrentChild(child)

      alert(
        t({
          id: 'notification.received',
          message: `Neue Nachricht betreffend ${child.firstName}.`,
        })
      )
    } else {
      alert(
        t({
          id: 'notification.receivedWithoutFamily',
          message: `Neue Nachricht.`,
        })
      )
    }
  })

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
    const child = state.family.children.find((child) => child.id === notification.notification.data.childId)
    state.setNewMessageId(notification.notification.data.messageId)
    if (child) {
      state.setCurrentChild(child)
    }
  })
}

const registerDeviceOnBackend = (props: { guardian: Guardian & { institutionId: string }; token: string }) => {
  console.log('registerDeviceForGuardian called')

  console.log('Registering device on backend with data', props.guardian.id, props.token)
  fetch(
    `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${props.guardian.institutionId}/guardian/register-device?guardianId=${props.guardian.id}`,
    {
      method: 'PATCH',
      headers: { accept: 'application/json' },
      body: JSON.stringify({ guardianId: props.guardian.id, token: props.token }),
    }
  ).then((response) => console.log('Register response', response.status, response.statusText))
}
