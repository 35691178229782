import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Collapse, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { toDateString } from '../../helper/format-date'
import translateBookingMutationApprovalState from '../../helper/translate-booking-mutation-approval-state'
import translateModuleMutationCancellationReason from '../../helper/translate-module-mutation-cancellation-reason'
import {
  BookingMutationApprovalState,
  Institution,
  ModuleMutationCancellationReason,
  ModuleMutationType,
} from '../../types'
import { Field } from '../form/field'

export type BookingMutation = {
  id: string
  title: string
  date: string
  contractId?: string
  effectiveAt?: string
  comment: string
  group?: string
  approvalState: BookingMutationApprovalState
  mutationType?: ModuleMutationType
  approvedNote?: string
  reason?: ModuleMutationCancellationReason
  location?: string
  ageGroupName?: string
}

const BookingMutationCard = ({
  bookingMutation,
  institution,
  onDelete,
}: {
  bookingMutation: BookingMutation
  institution: Institution
  onDelete: () => void
}) => {
  const { Panel } = Collapse

  const onDeleteModuleCancellation = async () => {
    if (bookingMutation.contractId) {
      const body = JSON.stringify(bookingMutation)

      await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/guardian/module-mutation?contractId=${bookingMutation.contractId}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
          },
          body,
        }
      )
      onDelete()
    }
  }

  const popConfirmText =
    bookingMutation.mutationType === 'cancellation'
      ? t({ message: `Abmeldung löschen?` })
      : t({ message: `Zusatzbuchung löschen?` })

  return (
    <Collapse
      style={{
        marginBottom: '0.5em',
        borderRightWidth: '8px',
        borderRightColor: cardColor(bookingMutation.approvalState),
      }}
    >
      <Panel
        header={
          <div
            style={{
              flexWrap: 'wrap',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <b>{`${toDateString(new Date(bookingMutation.date))}: ${bookingMutation.title}`}</b>
              {bookingMutation.location && ` (${bookingMutation.location})`}
            </div>

            <div
              style={{
                flexGrow: '1',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '0.5em',
              }}
            >
              {bookingMutation.mutationType !== 'cancellation' && (
                <div style={{ textAlign: 'end' }}>
                  <ApprovalState approvalState={bookingMutation.approvalState} />
                </div>
              )}
              <div onClick={(e) => e.stopPropagation()} style={{ textAlign: 'end' }}>
                <Popconfirm
                  title={popConfirmText}
                  onConfirm={onDeleteModuleCancellation}
                  okText={t({ message: 'Löschen' })}
                  cancelText={t({ message: 'Abbrechen' })}
                  disabled={dayjs(bookingMutation.effectiveAt).subtract(1, 'day').endOf('day').isBefore(dayjs())}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    disabled={dayjs(bookingMutation.effectiveAt).subtract(1, 'day').endOf('day').isBefore(dayjs())}
                  />
                </Popconfirm>
              </div>
            </div>
          </div>
        }
        key={bookingMutation.id}
      >
        {bookingMutation.approvedNote && (
          <Field label={t({ message: 'Entscheidungsnotiz' })}>
            <>{bookingMutation.approvedNote}</>
          </Field>
        )}

        {bookingMutation.ageGroupName && (
          <Field label={t({ message: `Gruppe` })}>
            <>{bookingMutation.ageGroupName}</>
          </Field>
        )}

        {bookingMutation.reason && (
          <Field label={t({ message: 'Grund' })}>
            <>{translateModuleMutationCancellationReason(bookingMutation.reason)}</>
          </Field>
        )}

        <Field label={t({ message: 'Kommentar' })}>
          <>{bookingMutation.comment}</>
        </Field>
        {bookingMutation.group && (
          <Field label={t({ message: 'Gruppeneinteilung' })}>
            <>{bookingMutation.group}</>
          </Field>
        )}
      </Panel>
    </Collapse>
  )
}

const cardColor = (approvalState: BookingMutationApprovalState): string => {
  switch (approvalState) {
    case 'new':
      return 'lightgrey'
    case 'accepted':
      return 'var(--main-accent-color)'
    case 'rejected':
      return 'var(--main-fg-color)'
  }
}

const ApprovalIcon = (props: { approvalState: BookingMutationApprovalState }) => {
  switch (props.approvalState) {
    case 'new':
      return <QuestionCircleOutlined style={{ fontSize: '2em', paddingLeft: '0.2em' }} />
    case 'accepted':
      return <CheckCircleOutlined style={{ fontSize: '2em', paddingLeft: '0.2em' }} />
    case 'rejected':
      return <CloseCircleOutlined style={{ fontSize: '2em', paddingLeft: '0.2em' }} />
  }
}

const ApprovalState = (props: { approvalState: BookingMutationApprovalState }) => {
  return (
    <div style={{ color: cardColor(props.approvalState), display: 'flex', flexDirection: 'row-reverse' }}>
      <ApprovalIcon approvalState={props.approvalState} />
      <div>{translateBookingMutationApprovalState(props.approvalState)} </div>
    </div>
  )
}

export default BookingMutationCard
