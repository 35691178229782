import { useEffect } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import GuardianPage from '../../../components/guardian-page/guardian-page'
import { useInstitutionContext } from '../institution-context'
import GuardianBookingsPage from './guardian-bookings-page'
import GuardianCancellationsPage from './guardian-cancellations-page'
import { useGuardianContext } from './guardian-context'
import GuardianFamilyPage from './guardian-family-page'
import GuardianHomePage from './guardian-home-page'
import GuardianSettingsPage from './guardian-settings-page'
import NewChildRouter from './new-child-form/new-child-router'
import NewHolidayCarePage from './new-holiday-care-form'
import { HolidayCareBookingConfirmation } from './new-holiday-care-form/holiday-care-booking-confirmation'
import { NextSchoolYearRouter } from './next-school-year/next-school-year-router'

const GuardianRouter = () => {
  const { institution } = useInstitutionContext()
  const user = useGuardianContext((state) => state.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/institution-index')
    }
  }, [user, navigate])

  return (
    <GuardianPage institution={institution}>
      <Outlet />
      <Routes>
        <Route path="bookings/" element={<GuardianBookingsPage />} />
        <Route path="home/" element={<GuardianHomePage />} />
        <Route path="family/" element={<GuardianFamilyPage />} />
        <Route path="cancellations/" element={<GuardianCancellationsPage />} />
        <Route path="*" element={<>404</>} />
        <Route path="new-holiday-care-page/:childId/*" element={<NewHolidayCarePage />} />
        <Route path="new-holiday-care-page/confirmation" element={<HolidayCareBookingConfirmation />} />
        <Route path="new-child-form/*" element={<NewChildRouter />} />
        <Route path="settings/" element={<GuardianSettingsPage />} />
        <Route path="next-school-year/*" element={<NextSchoolYearRouter />} />
      </Routes>
    </GuardianPage>
  )
}

export default GuardianRouter
