import { Trans } from '@lingui/macro'
import { Card, Typography } from 'antd'
import { Child, Guardian, Institution, RegistrationConfiguration } from '../../../types'
import ContractCard from '../../contract-card'
const { Title } = Typography

type Props = {
  child: Child | undefined
  guardian: Guardian
  institution: Institution & { registrationConfig: RegistrationConfiguration }
}

const ChildCardContractSection = ({ child, guardian, institution }: Props) => {
  if (!child) return <></>

  return (
    <Card
      size="small"
      type="inner"
      title={
        <div
          style={{
            whiteSpace: 'pre-wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Trans>Verträge</Trans>
        </div>
      }
      style={{ marginBottom: 8 }}
    >
      {child.contracts.filter((contract) => contract.state === 'accepted').length > 0 && (
        <>
          <Title level={5}>
            <Trans>Aktiv</Trans>
          </Title>
          {child.contracts
            .filter(
              (contract) =>
                contract.state === 'accepted' &&
                (contract.endDate === undefined || new Date(contract.endDate).getTime() > new Date().getTime())
            )
            .map((contract) => (
              <ContractCard key={contract.id} guardian={guardian} contract={contract} institution={institution} />
            ))}
        </>
      )}

      {child.contracts.filter((contract) => contract.state === 'new').length > 0 && (
        <>
          <Title level={5}>
            <Trans>Neu und noch keine Rückmeldung</Trans>
          </Title>
          {child.contracts
            .filter((contract) => contract.state === 'new')
            .map((contract) => (
              <ContractCard key={contract.id} guardian={guardian} contract={contract} institution={institution} />
            ))}
        </>
      )}

      {child.contracts.filter((contract) => contract.state === 'waitinglist').length > 0 && (
        <>
          <Title level={5}>
            <Trans>Warteliste</Trans>
          </Title>
          {child.contracts
            .filter((contract) => contract.state === 'waitinglist')
            .map((contract) => (
              <ContractCard key={contract.id} guardian={guardian} contract={contract} institution={institution} />
            ))}
        </>
      )}

      {child.contracts.filter((contract) => contract.state === 'waiting_for_decision').length > 0 && (
        <>
          <Title level={5}>
            <Trans>Warten Auf Elternrückmeldung</Trans>
          </Title>
          {child.contracts
            .filter((contract) => contract.state === 'waiting_for_decision')
            .map((contract) => (
              <ContractCard key={contract.id} guardian={guardian} contract={contract} institution={institution} />
            ))}
        </>
      )}

      {child.contracts.filter((contract) => contract.state === 'cancelled').length > 0 && (
        <>
          <Title level={5}>
            <Trans>Abgelehnt</Trans>
          </Title>
          {child.contracts
            .filter((contract) => contract.state === 'cancelled')
            .map((contract) => (
              <ContractCard key={contract.id} guardian={guardian} contract={contract} institution={institution} />
            ))}
        </>
      )}
    </Card>
  )
}

export default ChildCardContractSection
