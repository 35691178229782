import { t } from '@lingui/macro'
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import ChildBaseInformationForm, {
  NewChildBaseInformation,
} from '../../../../components/child/child-form/child-base-information-form'
import ChildFurtherInformationForm, {
  NewChildFurtherInformation,
} from '../../../../components/child/child-form/child-further-information-form'
import ChildImportantInformationForm, {
  NewChildImportantInformation,
} from '../../../../components/child/child-form/child-important-information-form'
import FormPage from '../../../../components/form/form-page'
import { getActiveContractOrUndefined } from '../../../../helper/get-active-contract'
import { Child, Contract, RegistrationConfiguration, SignatureData } from '../../../../types'
import { useInstitutionContext } from '../../institution-context'
import { useGuardianContext } from '../guardian-context'
import { useEffect, useMemo, useState } from 'react'
import ModuleFormWithSchool from '../../../../components/child/child-form/module-form/module-form-with-school'
import ModuleFormNoSchool from '../../../../components/child/child-form/module-form/module-form-no-school'
import { ModuleBooking } from '../../registration/registration-context'
import ConfirmationFormPage from '../../registration/form-pages/confirmation-form-page'
import { RegistrationErrorPage } from '../../registration/registration-error-page'
import DoneFormPage from '../../registration/form-pages/done-form-page'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { addNewContract } from '../../../../api/new-contract'

const AddChildContractRouter = () => {
  const { family, fetchFamily, taxInformation } = useGuardianContext()
  const { institution } = useInstitutionContext()
  const navigate = useNavigate()
  const { childId } = useParams()
  const [newChildBaseInformation, setNewChildBaseInformation] = useState<NewChildBaseInformation | undefined>()
  const [newChildFurtherInformation, setNewChildFurtherInformation] = useState<NewChildFurtherInformation | undefined>()
  const [newChildImportantInformation, setNewChildImportantInformation] = useState<
    NewChildImportantInformation | undefined
  >()
  const [moduleBooking, setModuleBooking] = useState<ModuleBooking | undefined>()

  const child = useMemo(() => family?.children.find((child) => child.id === childId), [family, childId])
  const activeContract = useMemo(() => {
    return child ? getActiveContractOrUndefined(child.contracts) : undefined
  }, [child])

  useEffect(() => {
    if (child) {
      setNewChildBaseInformation(convertToNewChildBaseInformation(child, activeContract))
      setNewChildFurtherInformation(convertToNewChildFurtherInformation(child))
      setNewChildImportantInformation(convertToNewChildImportantInformation(child))
      setModuleBooking({
        startDate: dayjs(institution?.registrationConfig.schoolYearStartDate).toDate(),
        bookings: [],
        flexibleBooking: false,
        locationId: activeContract?.locationId,
      })
    }
  }, [child, activeContract, institution?.registrationConfig.schoolYearStartDate])

  const updateChild = async (childData: Omit<Child, 'contracts'>) => {
    if (family) {
      const body = JSON.stringify(childData)

      await fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/child-data`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
        body,
      })
      fetchFamily()
    }
  }

  const handleSubmitChildBaseInformation = async (data: NewChildBaseInformation) => {
    setNewChildBaseInformation(data)
    navigate('further-information')
  }

  const handleSubmitChildFurtherInformation = async (data: NewChildFurtherInformation) => {
    setNewChildFurtherInformation(data)
    navigate('important-information')
  }

  const handleSubmitChildImportantInformation = async (data: NewChildImportantInformation) => {
    setNewChildImportantInformation(data)
    navigate('module-booking')
  }

  const handleSubmitModuleBooking = async (data: ModuleBooking) => {
    setModuleBooking(data)
    navigate('confirmation')
  }

  const handleSubmitConfirm = async (data: SignatureData) => {
    if (
      newChildBaseInformation &&
      newChildFurtherInformation &&
      newChildImportantInformation &&
      moduleBooking &&
      childId &&
      family &&
      institution?.registrationConfig
    ) {
      await updateChild(
        convertToDomainChild(newChildBaseInformation, newChildFurtherInformation, newChildImportantInformation, childId)
      )
      await addNewContract(
        institution,
        family.id,
        convertToDomainContract(
          newChildBaseInformation,
          newChildImportantInformation,
          moduleBooking,
          institution?.registrationConfig,
          childId
        ),
        data
      )
      fetchFamily()
    }
    navigate('../')
  }

  return (
    <>
      <Outlet />
      <Routes>
        {newChildBaseInformation && (
          <Route
            path="base-information"
            element={
              <FormPage title={t({ message: 'Basis Informationen' })}>
                <ChildBaseInformationForm
                  handleSubmit={handleSubmitChildBaseInformation}
                  isOutOfTown={false}
                  defaultValues={newChildBaseInformation}
                />
              </FormPage>
            }
          />
        )}
        {newChildFurtherInformation && (
          <Route
            path="further-information"
            element={
              <FormPage title={t({ message: 'Weitere Informationen' })}>
                <ChildFurtherInformationForm
                  handleSubmit={handleSubmitChildFurtherInformation}
                  defaultValues={newChildFurtherInformation}
                />
              </FormPage>
            }
          />
        )}
        {newChildImportantInformation && (
          <Route
            path="important-information"
            element={
              <FormPage title={t({ message: 'Wichtige Informationen' })}>
                <ChildImportantInformationForm
                  handleSubmit={handleSubmitChildImportantInformation}
                  defaultValues={newChildImportantInformation}
                />
              </FormPage>
            }
          />
        )}
        {newChildBaseInformation && (
          <Route
            path="module-booking"
            element={
              <FormPage title={t({ message: 'Modulauswahl' })}>
                {newChildBaseInformation.schoolId ? (
                  <ModuleFormWithSchool
                    schoolId={newChildBaseInformation.schoolId}
                    defaultValues={moduleBooking}
                    handleSubmit={handleSubmitModuleBooking}
                    taxInformation={taxInformation}
                    previousContract={activeContract}
                  />
                ) : (
                  <ModuleFormNoSchool defaultValues={moduleBooking} handleSubmit={handleSubmitModuleBooking} />
                )}
              </FormPage>
            }
          />
        )}
        {family && (
          <Route
            path="confirmation"
            element={
              <ConfirmationFormPage
                guardian1={family.guardians[0]}
                guardian2={family.guardians[1]}
                handlePrevious={() => navigate('../module-booking')}
                handleSubmit={handleSubmitConfirm}
              />
            }
            errorElement={<RegistrationErrorPage />}
          />
        )}
        <Route path="done" element={<DoneFormPage />} errorElement={<RegistrationErrorPage />} />
        <Route path="*" element={<>404</>} />
      </Routes>
    </>
  )
}

export { AddChildContractRouter }

const convertToNewChildBaseInformation = (child: Child, contract: Contract | undefined): NewChildBaseInformation => {
  const healthInsurance = child.healthInsurance || ''
  const healthInsuranceName = healthInsurance.split(',')[0].trim()
  const healthInsuranceNumber = healthInsurance.split(',')[1]?.trim()
  const liabilityInsurance = child.liablityInsurance || ''
  const liablityInsuranceName = liabilityInsurance.split(',')[0].trim()
  const liablityInsuranceNumber = liabilityInsurance.split(',')[1]?.trim()

  return {
    familyId: child.familyId,
    firstName: child.firstName,
    lastName: child.lastName,
    sex: child.sex || 'other',
    dateOfBirth: child.dateOfBirth,
    nationality: child.nationality || '',
    familyLanguage: child.familyLanguage,
    schoolId: contract?.schoolId,
    pickUp: child.pickUp,
    healthInsuranceName,
    healthInsuranceNumber,
    liablityInsuranceName,
    liablityInsuranceNumber,
    familyDoctorName: child.familyDoctorName,
    familyDoctorAddress: child.familyDoctorAddress,
    dentistName: child.dentistName,
    dentistAddress: child.dentistAddress,
    isOutOfTown: false,
    schoolLevel: contract?.schoolLevel || 'school',
    familyDoctorPhone: child.familyDoctorPhone,
    generalHealth: child.generalHealth,
    diseases: child.diseases,
    hasAllergies: child.hasAllergies,
    allergies: child.allergies,
    needsMedicaments: child.needsMedicaments,
    neededMedicaments: child.neededMedicaments,
    remarks: child.remarks,
    religion: child.religion,
    livesWith: child.livesWith,
  }
}

const convertToNewChildFurtherInformation = (child: Child): NewChildFurtherInformation => {
  return {
    emergencyContactFirstName: child.emergencyContactFirstName,
    emergencyContactLastName: child.emergencyContactLastName,
    emergencyContactPhone: child.emergencyContactPhone,
    emergencyContactRemarks: child.emergencyContactRemarks,
    contactPreference: child.contactPreference,
  }
}

const convertToNewChildImportantInformation = (child: Child): NewChildImportantInformation => {
  return {
    collectors: child.collectors,
    collectorsStrategy: child.collectorsStrategy,
    hasCollectors: child.hasCollectors,
    wayHome: child.wayHome,
    menuSelection: child.menuSelection,
  }
}

const convertToDomainChild = (
  newChildBaseInformation: NewChildBaseInformation,
  newChildFurtherInformation: NewChildFurtherInformation,
  newChildImportantInformation: NewChildImportantInformation,
  childId: string
): Omit<Child, 'contracts'> => {
  return {
    id: childId,
    familyId: newChildBaseInformation.familyId,
    firstName: newChildBaseInformation.firstName,
    lastName: newChildBaseInformation.lastName,
    sex: newChildBaseInformation.sex,
    dateOfBirth: newChildBaseInformation.dateOfBirth,
    nationality: newChildBaseInformation.nationality,
    familyLanguage: newChildBaseInformation.familyLanguage,
    isOutOfTown: newChildBaseInformation.isOutOfTown,
    menuSelection: newChildImportantInformation.menuSelection,
    pickUp: newChildBaseInformation.pickUp,
    healthInsurance: `${newChildBaseInformation.healthInsuranceName}, ${newChildBaseInformation.healthInsuranceNumber}`,
    liablityInsurance: `${newChildBaseInformation.liablityInsuranceName}, ${newChildBaseInformation.liablityInsuranceNumber}`,
    familyDoctorName: newChildBaseInformation.familyDoctorName,
    familyDoctorAddress: newChildBaseInformation.familyDoctorAddress,
    familyDoctorPhone: newChildBaseInformation.familyDoctorPhone,
    generalHealth: newChildBaseInformation.generalHealth,
    diseases: newChildBaseInformation.diseases,
    hasAllergies: newChildBaseInformation.hasAllergies,
    allergies: newChildBaseInformation.allergies,
    needsMedicaments: newChildBaseInformation.needsMedicaments,
    neededMedicaments: newChildBaseInformation.neededMedicaments,
    remarks: newChildBaseInformation.remarks,
    contactPreference: newChildFurtherInformation.contactPreference,
    emergencyContactFirstName: newChildFurtherInformation.emergencyContactFirstName,
    emergencyContactLastName: newChildFurtherInformation.emergencyContactLastName,
    emergencyContactPhone: newChildFurtherInformation.emergencyContactPhone,
    emergencyContactRemarks: newChildFurtherInformation.emergencyContactRemarks,
    wayHome: newChildImportantInformation.wayHome,
    busLineAndTimeforWayHome: newChildImportantInformation.busLineAndTimeforWayHome,
    hasCollectors: newChildImportantInformation.hasCollectors,
    collectorsStrategy: newChildImportantInformation.collectorsStrategy,
    allowedToBeAloneOnSchoolYard: newChildImportantInformation.allowedToBeAloneOnSchoolYard,
    allowedToDoHomework: newChildImportantInformation.allowedToDoHomework,
    allowedToBeTransportedByCar: newChildImportantInformation.allowedToBeTransportedByCar,
    allowedToReceiveMedicalHelp: newChildImportantInformation.allowedToReceiveMedicalHelp,
    allowedToUseTickSpray: newChildImportantInformation.allowedToUseTickSpray,
    allowedToUsePublicTransport: newChildImportantInformation.allowedToUsePublicTransport,
    allowedToBePhotographedForInternalUse: newChildImportantInformation.allowedToBePhotographedForInternalUse,
    allowedToBePhotographedForAds: newChildImportantInformation.allowedToBePhotographedForAds,
    allowedToContactTeachers: newChildImportantInformation.allowedToContactTeachers,
    allowedToContactSchoolSocialWorkers: newChildImportantInformation.allowedToContactSchoolSocialWorkers,
    visitsOtherInstitution: newChildImportantInformation.visitsOtherInstitution,
    visitsPhisioTherapy: newChildImportantInformation.visitsPhisioTherapy,
    isVaccinated: newChildImportantInformation.isVaccinated,
    isTakingNap: newChildImportantInformation.isTakingNap,
    collectors: newChildImportantInformation.collectors,
    religion: newChildBaseInformation.religion,
    livesWith: newChildBaseInformation.livesWith,
  }
}

const convertToDomainContract = (
  newChildBaseInformation: NewChildBaseInformation,
  newChildImportantInformation: NewChildImportantInformation,
  moduleBooking: ModuleBooking,
  registrationConfig: RegistrationConfiguration,
  childId: string
): Omit<Contract, 'createdAt' | 'state'> => {
  return {
    id: nanoid(),
    childId,
    startDate: moduleBooking.startDate!,
    endDate: registrationConfig.schoolYearEndDate ? dayjs(registrationConfig.schoolYearEndDate).toDate() : undefined,
    locationId: moduleBooking.locationId!,
    ageGroupId: moduleBooking.ageGroup!.id,
    bookedModules: moduleBooking.bookings,
    schoolId: newChildBaseInformation.schoolId,
    schoolLevel: newChildBaseInformation.schoolLevel,
    kinderGarten: newChildBaseInformation.kinderGarten,
    schoolOfferOverLunch: newChildImportantInformation.schoolOfferOverLunch,
    class: newChildBaseInformation.class,
    teacherName: newChildBaseInformation.teacherName,
    teacherContact: newChildBaseInformation.teacherContact,
  }
}
