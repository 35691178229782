import { t, Trans } from '@lingui/macro'
import { Button, Card, Modal, Typography } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { toDateString } from '../helper/format-date'
import { useGuardianContext } from '../routes/institution/guardian/guardian-context'
import { Contract, ContractDocument, Guardian, Institution, RegistrationConfiguration } from '../types'
import ContractDownloadLink from './contract-download-link'
import { Field } from './form/field'
import { LocalizedDatePicker } from './localized-date-picker'
import ContractAdditionalDocumentDownloadLink from './contract-additional-document-download-link'
const { Text } = Typography

const ContractCard = ({
  contract,
  institution,
}: {
  contract: Contract
  guardian: Guardian
  institution: Institution & { registrationConfig: RegistrationConfiguration }
}) => {
  const { fetchFamily } = useGuardianContext()

  const earliestCancelDate = dayjs(new Date())
    .add(institution.contractNoticePeriodInDays, 'days')
    .endOf('month')
    .toDate()
  const [cancelContractModalOpen, setCancelContractModalOpen] = useState(false)
  const [cancellationDate, setCancellationDate] = useState(earliestCancelDate)
  const [additionalFiles, setAdditionalFiles] = useState<ContractDocument[]>([])

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/contract-additional-documents?childId=${contract.childId}&contractId=${contract.id}`,
      { method: 'GET' }
    )
      .then((response) => response.json())
      .then((files) => setAdditionalFiles(files))
  }, [contract.childId, contract.id, institution?.id])

  const handleCancelContractModal = () => {
    setCancelContractModalOpen(false)
  }

  const handleCancelContractModalOk = async () => {
    const body = JSON.stringify({
      ...contract,
      endDate: cancellationDate,
    })

    await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/cancel-contract?contractId=${contract.id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )
    setCancelContractModalOpen(false)
    await fetchFamily()
  }

  return (
    <Card>
      <div
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.6em' }}>
          <Text>
            <b>
              <Trans>Erstellt Am</Trans>:
            </b>{' '}
            {toDateString(new Date(contract.createdAt))}
          </Text>
          <Text>
            <b>
              <Trans>Betreuung Ab</Trans>:
            </b>{' '}
            {toDateString(new Date(contract.startDate))}
          </Text>
          <Text>
            <b>
              <Trans>Betreuung bis</Trans>:
            </b>{' '}
            {contract.endDate ? (
              toDateString(new Date(contract.endDate))
            ) : (
              <Trans>Bis der Vertrag gekündigt wird.</Trans>
            )}
          </Text>
          {contract.adjustmentPeriodStartDate &&
            institution.registrationConfig.askForAdjustmentPeriodStartDate !== 'off' && (
              <Text>
                <b>
                  <Trans>Startdatum Eingewöhnung</Trans>:
                </b>
                {toDateString(new Date(contract.adjustmentPeriodStartDate))}
              </Text>
            )}
          {contract.state === 'accepted' && (
            <Text>
              <b>
                <Trans>Vertrag als Datei</Trans>:
              </b>
              <ContractDownloadLink
                key={`${contract.id}-PDF`}
                contractId={contract.id}
                institutionId={institution.id}
              />
            </Text>
          )}
          {additionalFiles.length > 0 && (
            <Text>
              <b>{t({ message: `Weitere Dateien:` })}</b>
              {additionalFiles.map((file) => (
                <ContractAdditionalDocumentDownloadLink
                  institutionId={institution.id}
                  key={file.fileName}
                  contractDocument={file}
                />
              ))}
            </Text>
          )}
        </div>
        {contract.cancelledBy === undefined ? (
          <Button onClick={() => setCancelContractModalOpen(true)}>{t({ message: `Vertrag kündigen` })}</Button>
        ) : (
          <Text italic>{t({ message: `Vertrag gekündigt` })}</Text>
        )}
        <Modal
          open={cancelContractModalOpen}
          closable={false}
          onCancel={handleCancelContractModal}
          onOk={handleCancelContractModalOk}
          title={t({ message: 'Vertrag kündigen' })}
        >
          <Text>
            {t`Sie können den Vertrag frühestens in ${
              institution.contractNoticePeriodInDays
            } Tagen auf Ende Monat kündigen. Somit ist das früheste Kündigungsdatum der ${toDateString(
              earliestCancelDate
            )}`}
          </Text>
          <Field label={t({ message: 'Datum' })}>
            <LocalizedDatePicker
              value={cancellationDate}
              onlyAllow="future"
              futureStartDate={dayjs(earliestCancelDate).subtract(1, 'day')}
              onChange={(value: string) => {
                setCancellationDate(dayjs(value).toDate())
              }}
            />
          </Field>
        </Modal>
      </div>
    </Card>
  )
}

export default ContractCard
