import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { AutoComplete, Input, Radio, Select } from 'antd'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import localizedCountryList from '../../../lib/localized-country-list'
import { useInstitutionContext } from '../../../routes/institution/institution-context'
import { KindergartenLevel, SchoolLevel, Sex } from '../../../types'
import ButtonSection from '../../form/button-section'
import { Field } from '../../form/field'
import PhoneNumberInput from '../../form/phone-number-input'
import SingleLineTextInput from '../../form/single-line-text-input'
import YesNoRadioButtons from '../../form/yes-no-radio-buttons'
import { LocalizedDatePicker } from '../../localized-date-picker'
import translateKindergartenLevel from '../../../helper/translate-kindergarten'
import { Religion, RELIGION_NAMES, RELIGIONS } from '../../../domain/child/religion'
import { LIVES_WITH_NAMES, LIVES_WITH_VALUES, LivesWith } from '../../../domain/child/lives-with'
import { SelectInput } from '../../select-input'

export type NewChildBaseInformation = {
  familyId: string
  firstName: string
  lastName: string
  sex: Sex
  dateOfBirth: Date
  nationality: string
  familyLanguage?: string
  class?: string
  kinderGarten?: KindergartenLevel
  schoolId?: string
  pickUp?: boolean
  teacherName?: string
  teacherContact?: string
  healthInsuranceName?: string
  healthInsuranceNumber?: string
  liablityInsuranceName?: string
  liablityInsuranceNumber?: string
  familyDoctorName: string
  familyDoctorAddress?: string
  dentistName?: string
  dentistAddress?: string
  isOutOfTown: boolean
  schoolLevel: SchoolLevel
  familyDoctorPhone: string
  generalHealth: 'good' | 'sick'
  diseases: string
  hasAllergies: boolean
  allergies?: string
  needsMedicaments: boolean
  neededMedicaments?: string
  remarks?: string
  religion: Religion | null
  livesWith: LivesWith | null
}

type Props = {
  handleSubmit: (data: NewChildBaseInformation) => void
  submitButtonText?: string
  handlePrevious?: () => void
  isOutOfTown: boolean
  defaultValues?: NewChildBaseInformation
}

const ChildBaseInformationForm = ({
  handleSubmit,
  submitButtonText,
  handlePrevious,
  isOutOfTown,
  defaultValues,
}: Props) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit: formHandleSubmit,
  } = useForm<NewChildBaseInformation>({
    defaultValues,
  })
  const {
    i18n: { locale },
  } = useLingui()

  const { institution } = useInstitutionContext()

  const schoolLevel = watch('schoolLevel')
  const watchHasAllergies = watch('hasAllergies')
  const watchNeedsMedication = watch('needsMedicaments')
  const watchGeneralHealth = watch('generalHealth')
  const watchSchoolId = watch('schoolId')
  const school = institution?.schools.find((s) => s.id === watchSchoolId) ?? null

  const availableSchoolLevels: SchoolLevel[] = useMemo(() => {
    const levels: SchoolLevel[] = []
    if (institution?.registrationConfig.hasSchoolLevelBaby) levels.push('baby')
    if (institution?.registrationConfig.hasSchoolLevelToddler) levels.push('toddler')
    if (institution?.registrationConfig.hasSchoolLevelKindergarten) levels.push('kindergarten')
    if (institution?.registrationConfig.hasSchoolLevelSchool) levels.push('school')
    return levels
  }, [
    institution?.registrationConfig.hasSchoolLevelBaby,
    institution?.registrationConfig.hasSchoolLevelKindergarten,
    institution?.registrationConfig.hasSchoolLevelSchool,
    institution?.registrationConfig.hasSchoolLevelToddler,
  ])

  const toddlerStartAgeInMonths = institution?.registrationConfig.toddlerStartAgeInMonths || 18
  const toddlerStartAgeText =
    toddlerStartAgeInMonths % 12 === 0 && toddlerStartAgeInMonths > 12
      ? t({ message: `ab ${toddlerStartAgeInMonths / 12} Jahre` })
      : `ab ${toddlerStartAgeInMonths} Monate`

  useEffect(() => {
    if (availableSchoolLevels.length === 1 && schoolLevel === undefined) {
      setValue('schoolLevel', availableSchoolLevels[0], { shouldDirty: true })
    }
  }, [availableSchoolLevels, schoolLevel, setValue])

  const countries = localizedCountryList(locale)

  useEffect(() => {
    if (watchGeneralHealth === 'good') {
      setValue('diseases', '')
    }
  }, [watchGeneralHealth, setValue])

  useEffect(() => {
    if (!watchNeedsMedication) {
      setValue('neededMedicaments', '')
    }
  }, [watchNeedsMedication, setValue])

  useEffect(() => {
    if (!watchHasAllergies) {
      setValue('allergies', '')
    }
  }, [watchHasAllergies, setValue])

  useEffect(() => {
    if (schoolLevel === 'baby' || schoolLevel === 'toddler') {
      setValue('kinderGarten', undefined)
      setValue('class', '')
      setValue('schoolId', '')
      setValue('pickUp', undefined)
      setValue('teacherName', '')
      setValue('teacherContact', '')
    } else if (schoolLevel === 'school') {
      setValue('kinderGarten', undefined)
      if (institution?.schools.length === 1) {
        setValue('schoolId', institution.schools[0].id)
      }
    }
  }, [institution?.schools, schoolLevel, setValue])

  const onSubmitHandler = formHandleSubmit((values) => {
    handleSubmit({ ...values, isOutOfTown })
  })

  const doAskForSchoolMandatory = institution?.registrationConfig.askForSchoolName === 'mandatory'
  const doAskForSchool =
    (institution?.registrationConfig.askForSchoolName === 'optional' || doAskForSchoolMandatory) &&
    !isOutOfTown &&
    institution?.schools?.length > 1

  const doAskForClassMandatory = institution?.registrationConfig.askForClass === 'mandatory'
  const doAskForClass =
    (institution?.registrationConfig.askForClass === 'optional' || doAskForClassMandatory) && !isOutOfTown

  const doAskForKindergartenMandatory = institution?.registrationConfig.askForKinderGarten === 'mandatory'
  const doAskForKindergarten =
    (institution?.registrationConfig.askForKinderGarten === 'optional' || doAskForKindergartenMandatory) && !isOutOfTown

  const doAskForTeacherNameMandatory = institution?.registrationConfig.askForTeacherName === 'mandatory'
  const doAskForTeacherName =
    (institution?.registrationConfig.askForTeacherName === 'optional' || doAskForTeacherNameMandatory) && !isOutOfTown

  const doAskForTeacherContactMandatory = institution?.registrationConfig.askForTeacherContact === 'mandatory'
  const doAskForTeacherContact =
    (institution?.registrationConfig.askForTeacherContact === 'optional' || doAskForTeacherContactMandatory) &&
    !isOutOfTown

  const doAskForPickupMandatory = institution?.registrationConfig.askForPickup === 'mandatory'
  const doAskForPickup =
    (institution?.registrationConfig.askForPickup === 'optional' || doAskForPickupMandatory) && !isOutOfTown

  const getSchoolNameLabel = (): string => {
    if (schoolLevel === 'kindergarten') {
      return institution?.features.buchrainPricing
        ? t({ message: `Standort Tagesstrukturen` })
        : t({ message: `Bezeichnung des Kindergartens` })
    }
    if (schoolLevel === 'school') {
      return t({ message: 'Bezeichnung der Schule' })
    }
    return ''
  }

  return (
    <>
      <Field label={t({ message: 'Vorname' }) + ' *'} error={errors?.firstName}>
        <SingleLineTextInput name="firstName" required={true} control={control} maxLength={50} />
      </Field>

      <Field label={t({ message: 'Nachname' }) + ' *'} error={errors?.lastName}>
        <SingleLineTextInput name="lastName" required={true} control={control} maxLength={50} />
      </Field>

      <Field label={t({ message: 'Geschlecht' }) + ' *'} error={errors?.sex}>
        <Controller
          control={control}
          {...register('sex')}
          rules={{ required: true }}
          render={({ field }) => (
            <Select id="sex" style={{ width: '100%' }} {...field}>
              <Select.Option key={'female'} value={'female'}>
                <Trans>Weiblich</Trans>
              </Select.Option>
              <Select.Option key={'male'} value={'male'}>
                <Trans>Männlich</Trans>
              </Select.Option>
              <Select.Option key={'other'} value={'other'}>
                <Trans>Divers</Trans>
              </Select.Option>
            </Select>
          )}
        />
      </Field>

      <Field label={t({ message: 'Geburtsdatum' }) + ' *'} error={errors?.dateOfBirth}>
        <Controller
          control={control}
          {...register('dateOfBirth', { required: true })}
          render={(props) => (
            <LocalizedDatePicker
              value={props.field.value}
              onChange={(dateString) => {
                props.field.onChange(dateString)
              }}
            />
          )}
        />
      </Field>

      {institution?.registrationConfig.askForChildNationality !== 'off' && (
        <Field
          label={
            t({ message: 'Nationalität' }) +
            (institution?.registrationConfig.askForChildNationality === 'mandatory' ? ' *' : '')
          }
          error={errors?.nationality}
        >
          <Controller
            control={control}
            {...register('nationality')}
            rules={{ required: institution?.registrationConfig.askForChildNationality === 'mandatory' }}
            render={({ field }) => (
              <Select
                id="nationality"
                style={{ width: '100%' }}
                {...field}
                options={countries}
                showSearch
                optionFilterProp="label"
                maxLength={50}
              />
            )}
          />
        </Field>
      )}

      {(institution?.registrationConfig.askForFamilyLanguage === 'mandatory' ||
        institution?.registrationConfig.askForFamilyLanguage === 'optional') && (
        <Field
          label={
            t({ message: 'Familiensprache' }) +
            (institution?.registrationConfig.askForFamilyLanguage === 'mandatory' ? ' *' : '')
          }
          error={errors?.familyLanguage}
        >
          <SingleLineTextInput
            required={institution?.registrationConfig.askForFamilyLanguage === 'mandatory'}
            name="familyLanguage"
            control={control}
            maxLength={50}
            autocomplete="language"
          />
        </Field>
      )}

      {institution?.registrationConfig.askForChildReligion !== 'off' && (
        <Field
          label={
            t({ message: `Konfession` }) +
            (institution?.registrationConfig.askForChildReligion === 'mandatory' ? ' *' : '')
          }
          error={errors?.religion}
        >
          <SelectInput
            name="religion"
            control={control}
            options={RELIGIONS.map((religion) => ({ value: religion, label: RELIGION_NAMES[religion] }))}
            rules={{ required: institution?.registrationConfig.askForChildReligion === 'mandatory' }}
          />
        </Field>
      )}
      {institution?.registrationConfig.askForChildLivesWith !== 'off' && (
        <Field
          label={
            t({ message: `Kind wohnhaft bei` }) +
            (institution?.registrationConfig.askForChildLivesWith === 'mandatory' ? ' *' : '')
          }
          error={errors?.livesWith}
        >
          <SelectInput
            name="livesWith"
            control={control}
            options={LIVES_WITH_VALUES.map((livesWith) => ({ value: livesWith, label: LIVES_WITH_NAMES[livesWith] }))}
            rules={{ required: institution?.registrationConfig.askForChildLivesWith === 'mandatory' }}
          />
        </Field>
      )}

      {availableSchoolLevels.length > 1 && (
        <Field label={t({ message: 'Alter / Schulische Stufe' }) + ' *'} error={errors.schoolLevel}>
          <Controller
            control={control}
            {...register('schoolLevel', {
              validate: (value) => {
                const schoolLevels = availableSchoolLevels
                return schoolLevels.includes(value)
              },
            })}
            render={({ field }) => (
              <Radio.Group {...field}>
                {institution?.registrationConfig.hasSchoolLevelBaby && (
                  <Radio value="baby">
                    <Trans>Säugling</Trans>{' '}
                    <small>
                      ({t({ message: `3-${institution.registrationConfig.toddlerStartAgeInMonths} Monate` })})
                    </small>
                  </Radio>
                )}
                {institution?.registrationConfig.hasSchoolLevelToddler && (
                  <Radio value="toddler">
                    <Trans>Kleinkind</Trans> <small>({toddlerStartAgeText})</small>
                  </Radio>
                )}
                {institution?.registrationConfig.hasSchoolLevelKindergarten && (
                  <Radio value="kindergarten">
                    <Trans>Kindergarten</Trans>
                  </Radio>
                )}
                {institution?.registrationConfig.hasSchoolLevelSchool && (
                  <Radio value="school">
                    <Trans>Schule</Trans>
                  </Radio>
                )}
              </Radio.Group>
            )}
          />
        </Field>
      )}

      {schoolLevel === 'kindergarten' && (
        <>
          {doAskForKindergarten && (
            <Field
              label={t({ message: 'Kindergarten' }) + (doAskForKindergartenMandatory ? ' *' : '')}
              error={errors?.kinderGarten}
            >
              <Controller
                control={control}
                {...register('kinderGarten', {
                  required: doAskForKindergartenMandatory,
                })}
                render={({ field }) => (
                  <Select id="kinderGarten" style={{ width: '100%' }} {...field}>
                    <Select.Option key={'first'} value={'first'}>
                      {translateKindergartenLevel('first')}
                    </Select.Option>
                    <Select.Option key={'second'} value={'second'}>
                      {translateKindergartenLevel('second')}
                    </Select.Option>
                  </Select>
                )}
              />
            </Field>
          )}
        </>
      )}

      {(schoolLevel === 'school' || schoolLevel === 'kindergarten') && (
        <>
          {doAskForSchool && (
            <Field label={getSchoolNameLabel() + (doAskForClassMandatory ? ' *' : '')} error={errors?.schoolId}>
              <Controller
                control={control}
                {...register('schoolId', {
                  required: (schoolLevel === 'school' || schoolLevel === 'kindergarten') && doAskForClassMandatory,
                })}
                render={({ field }) => (
                  <Select id="schoolId" style={{ width: '100%' }} {...field}>
                    {institution &&
                      institution.schools.map((school) => (
                        <Select.Option key={school.id} value={school.id}>
                          {school.name}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              />
            </Field>
          )}
          {(schoolLevel === 'school' || schoolLevel === 'kindergarten') && doAskForClass && (
            <Field
              label={
                (schoolLevel === 'school'
                  ? t({ message: 'Schulklasse (Bitte komplette Bezeichnung eingeben wie z.B. 3a)' })
                  : t({ message: 'Kindergartengruppe (Bitte komplette Bezeichnung eingeben)' })) +
                (doAskForClassMandatory ? '*' : '')
              }
              error={errors?.class}
            >
              <Controller
                control={control}
                {...register('class', {
                  required: institution?.registrationConfig.askForClass === 'mandatory',
                })}
                render={({ field }) => {
                  if (school && school.classes.length > 0) {
                    return (
                      <AutoComplete
                        id="class"
                        style={{ width: '100%' }}
                        {...field}
                        options={school.classes
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((schoolClass) => ({
                            schoolClass,
                            value: schoolClass.name,
                            label: schoolClass.name + ' - ' + schoolClass.teacherName,
                          }))}
                        onSelect={(e, { schoolClass }) => {
                          setValue('teacherName', schoolClass.teacherName)
                        }}
                      />
                    )
                  }
                  return <Input {...field} type={'text'} maxLength={50} />
                }}
              />
            </Field>
          )}

          {doAskForTeacherName && (
            <Field
              label={t({ message: 'Name Lehrperson' }) + (doAskForTeacherNameMandatory ? ' *' : '')}
              error={errors?.teacherName}
            >
              <SingleLineTextInput
                required={doAskForTeacherNameMandatory}
                name="teacherName"
                maxLength={50}
                control={control}
              />
            </Field>
          )}

          {doAskForPickup && (
            <Field
              label={t({ message: 'Abholdienst erwünscht' }) + (doAskForPickupMandatory ? ' *' : '')}
              error={errors.pickUp}
            >
              <YesNoRadioButtons
                control={control}
                register={register}
                required={doAskForPickupMandatory}
                name="pickUp"
                validate={() => true}
              />
            </Field>
          )}

          {doAskForTeacherContact && (
            <Field
              label={t({ message: 'Kontakt Lehrperson' }) + (doAskForTeacherContactMandatory ? ' *' : '')}
              error={errors?.teacherContact}
            >
              <SingleLineTextInput
                required={doAskForTeacherContactMandatory}
                name="teacherContact"
                control={control}
                maxLength={100}
              />
            </Field>
          )}
        </>
      )}

      {(institution?.registrationConfig.askForHealthInsuranceName === 'mandatory' ||
        institution?.registrationConfig.askForHealthInsuranceName === 'optional') && (
        <Field
          label={
            t({ message: 'Name Kranken-/Unfallversicherung' }) +
            (institution?.registrationConfig.askForHealthInsuranceName === 'mandatory' ? ' *' : '')
          }
          error={errors?.healthInsuranceName}
        >
          <SingleLineTextInput
            required={institution?.registrationConfig.askForHealthInsuranceName === 'mandatory'}
            name="healthInsuranceName"
            control={control}
            maxLength={100}
          />
        </Field>
      )}

      {(institution?.registrationConfig.askForHealthInsuranceNumber === 'mandatory' ||
        institution?.registrationConfig.askForHealthInsuranceNumber === 'optional') && (
        <Field
          label={
            t({ message: 'Versichertennummer Kranken-/Unfallversicherung' }) +
            (institution?.registrationConfig.askForHealthInsuranceNumber === 'mandatory' ? ' *' : '')
          }
          error={errors?.healthInsuranceNumber}
        >
          <SingleLineTextInput
            name="healthInsuranceNumber"
            required={institution?.registrationConfig.askForHealthInsuranceNumber === 'mandatory'}
            control={control}
            maxLength={50}
          />
        </Field>
      )}

      {(institution?.registrationConfig.askForLiablityInsuranceName === 'mandatory' ||
        institution?.registrationConfig.askForLiablityInsuranceName === 'optional') && (
        <Field
          label={
            t({ message: 'Name Haftpflichtversicherung' }) +
            (institution?.registrationConfig.askForLiablityInsuranceName === 'mandatory' ? ' *' : '')
          }
          error={errors?.liablityInsuranceName}
        >
          <SingleLineTextInput
            name="liablityInsuranceName"
            required={institution?.registrationConfig.askForLiablityInsuranceName === 'mandatory'}
            control={control}
            maxLength={100}
          />
        </Field>
      )}

      {(institution?.registrationConfig.askForLiablityInsuranceNumber === 'mandatory' ||
        institution?.registrationConfig.askForLiablityInsuranceNumber === 'optional') && (
        <Field
          label={
            t({ message: 'Versichertennummer Haftpflichtversicherung' }) +
            (institution?.registrationConfig.askForLiablityInsuranceNumber === 'mandatory' ? ' *' : '')
          }
          error={errors?.liablityInsuranceNumber}
        >
          <SingleLineTextInput
            name="liablityInsuranceNumber"
            required={institution?.registrationConfig.askForLiablityInsuranceNumber === 'mandatory'}
            control={control}
            maxLength={50}
          />
        </Field>
      )}

      {institution?.registrationConfig.askForFamilyDoctor !== 'off' && (
        <>
          <Field
            label={
              t({ message: 'Name Hausarzt (oder Praxis)' }) +
              (institution?.registrationConfig.askForFamilyDoctor === 'mandatory' ? ' *' : '')
            }
            error={errors?.familyDoctorName}
          >
            <SingleLineTextInput
              name="familyDoctorName"
              required={institution?.registrationConfig.askForFamilyDoctor === 'mandatory'}
              control={control}
              maxLength={50}
            />
          </Field>
          {(institution?.registrationConfig.askForFamilyDoctorAddress === 'mandatory' ||
            institution?.registrationConfig.askForFamilyDoctorAddress === 'optional') && (
            <Field
              label={
                t({ message: 'Adresse Hausarzt' }) +
                (institution?.registrationConfig.askForFamilyDoctorAddress === 'mandatory' ? ' *' : '')
              }
              error={errors?.familyDoctorAddress}
            >
              <SingleLineTextInput
                name="familyDoctorAddress"
                required={institution?.registrationConfig.askForFamilyDoctorAddress === 'mandatory'}
                control={control}
                maxLength={1000}
              />
            </Field>
          )}
          <Field
            label={
              t({ message: 'Telefonnummer Hausarzt' }) +
              (institution?.registrationConfig.askForFamilyDoctor === 'mandatory' ? ' *' : '')
            }
            error={errors?.familyDoctorPhone}
          >
            <PhoneNumberInput
              name="familyDoctorPhone"
              required={institution?.registrationConfig.askForFamilyDoctor === 'mandatory'}
              control={control}
            />
          </Field>
        </>
      )}

      {institution?.registrationConfig.askForDentist !== 'off' && (
        <>
          <Field
            label={
              t({ message: 'Name Zahnarzt (oder Zahnarztpraxis)' }) +
              (institution?.registrationConfig.askForDentist === 'mandatory' ? ' *' : '')
            }
            error={errors?.dentistName}
          >
            <SingleLineTextInput
              name="dentistName"
              required={institution?.registrationConfig.askForDentist === 'mandatory'}
              control={control}
              maxLength={50}
            />
          </Field>
          {(institution?.registrationConfig.askForDentistAddress === 'mandatory' ||
            institution?.registrationConfig.askForDentistAddress === 'optional') && (
            <Field
              label={
                t({ message: 'Adresse Zahnarzt' }) +
                (institution?.registrationConfig.askForDentistAddress === 'mandatory' ? ' *' : '')
              }
              error={errors?.dentistAddress}
            >
              <SingleLineTextInput
                name="dentistAddress"
                required={institution?.registrationConfig.askForDentistAddress === 'mandatory'}
                control={control}
                maxLength={1000}
              />
            </Field>
          )}
        </>
      )}

      {institution?.registrationConfig.askForChildGeneralHealth !== 'off' && (
        <Field
          label={
            t({ message: 'Allgemeiner Gesundheitszustand?' }) +
            (institution?.registrationConfig.askForChildGeneralHealth === 'mandatory' ? ' *' : '')
          }
          error={errors.generalHealth}
        >
          <Controller
            control={control}
            {...register('generalHealth', {
              validate: (value) => {
                return value === 'good' || value === 'sick'
              },
            })}
            rules={{ required: institution?.registrationConfig.askForChildGeneralHealth === 'mandatory' }}
            render={({ field: { onChange, value } }) => (
              <Radio.Group
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                value={value}
              >
                <Radio value="good">
                  <Trans>Gut</Trans>
                </Radio>
                <Radio value="sick">
                  <Trans>Mein Kind leidet unter Krankheiten</Trans>
                </Radio>
              </Radio.Group>
            )}
          />
        </Field>
      )}

      {watchGeneralHealth === 'sick' && (
        <Field label={t({ message: 'Welche?' }) + ' *'} error={errors?.diseases}>
          <SingleLineTextInput name="diseases" required={true} control={control} maxLength={1000} />
        </Field>
      )}

      <Field
        label={t({ message: 'Braucht das Kind regelmässig Medikamente?' }) + ' *'}
        error={errors?.needsMedicaments}
      >
        <YesNoRadioButtons control={control} register={register} name="needsMedicaments" required={true} />
      </Field>

      {watchNeedsMedication && (
        <Field label={t({ message: 'Welche?' }) + ' *'} error={errors?.neededMedicaments}>
          <SingleLineTextInput
            name="neededMedicaments"
            required={watchNeedsMedication}
            control={control}
            maxLength={1000}
          />
        </Field>
      )}

      <Field label={t({ message: 'Allergien / Unverträglichkeiten beim Essen' }) + ' *'} error={errors?.hasAllergies}>
        <YesNoRadioButtons control={control} register={register} name="hasAllergies" required={true} />
      </Field>

      {watchHasAllergies && (
        <Field label={t({ message: 'Welche?' }) + ' *'} error={errors?.allergies}>
          <SingleLineTextInput name="allergies" required={watchHasAllergies} control={control} maxLength={1000} />
        </Field>
      )}

      <Field label={t({ message: 'Weitere Bemerkungen' })} error={errors?.remarks}>
        <SingleLineTextInput name="remarks" required={false} control={control} maxLength={1000} />
      </Field>

      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={onSubmitHandler}
        onPrevious={handlePrevious && handlePrevious}
      />
    </>
  )
}

export default ChildBaseInformationForm
